<template>
    <div class="EnterpriseStateInfo">
        <div @click="$router.go(-1)" class="backPreviousPage f-c"><i class="el-icon-d-arrow-left"></i>上一页</div>
        <template v-if="EnterpriseInfo.records && EnterpriseInfo.records.length">
            <div class="info-item f-c-b" v-for="(item, index) of EnterpriseInfo.records" :key="index">
                <div class="f-c f1" @click="showEnterpriseInfo(item)">
                    <el-image :src="item.eimage1" fit="fill" style="width: 100px; height: 100px"></el-image>
                    <div class="base-info f-cl-b">
                        <span>{{ item.resultNote }}</span>
                        <span>{{ item.enterpriseName }}</span>
                        <span>{{ item.applyNote }}</span>
                    </div>
                </div>
            </div>
        </template>
        <el-empty v-else description="暂无信息！"></el-empty>
        <Dialog title="企业信息详情" width="25%" :visible="dialogShow" @closeDialog="closeDialog">
            <div class="f-cl-c enterprise-details">
                <el-image :src="eimage1" fit="cover" style="width: 150px; height: 150px;margin-bottom: 20px;"></el-image>
                <div class="details-item f-c" v-for="(item, index) of detailInfo" :key="index">
                    <span>{{ item.title }}：</span>
                    <span class="f1">{{ item.value }}</span>
                </div>
                <div class="details-state">{{ eApply }}</div>
                <!-- 申请类型(J,用户申请.I,企业邀请加入,Q用户退出企业,D管理员踢出用户,XD企业解散清退,XQ企业解散时主动退出) -->
                <!-- handleFlag -是否已处理  S-已处理 W-待处理  result 处理结果  Y-成功 N-不成功 -->
                <template v-if="DetailItem.applyType == 'J'">
                    <template v-if="DetailItem.handleFlag == 'S'">
                        <el-button type="primary" @click="userApprovalEmptyOne(DetailItem.applyId)"
                            v-if="DetailItem.result == 'N'">
                            清除消息</el-button>
                    </template>
                    <template v-if="DetailItem.handleFlag == 'W'">
                        <el-button type="primary" @click="userRevokeJoinEnterprise(DetailItem.applyId)">撤销加入</el-button>
                    </template>
                </template>
                <div v-if="DetailItem.applyType == 'Q'">
                    <template v-if="DetailItem.handleFlag == 'S'">
                        <el-button type="primary" @click="userApprovalEmptyOne(DetailItem.applyId)"
                            v-if="DetailItem.result == 'Y'">
                            清除消息
                        </el-button>
                        <div class="f-c" v-else>
                            <el-button type="primary" @click="userApprovalEmptyOne(DetailItem.applyId)">清除消息</el-button>
                            <el-button type="primary" @click="userApplyQuitEnterprise(DetailItem.enterpriseId)">再次申请
                            </el-button>
                        </div>
                    </template>
                    <template v-if="DetailItem.handleFlag == 'W'">
                        <el-button type="primary" @click="userRevokeJoinEnterprise(DetailItem.applyId)">
                            撤销退出</el-button>
                    </template>
                </div>
                <el-button type="primary" @click="userApprovalEmptyOne(DetailItem.applyId)"
                    v-if="DetailItem.applyType == 'D' || DetailItem.applyType == 'XD' || DetailItem.applyType == 'XQ'">
                    清除消息
                </el-button>
                <div v-if="DetailItem.applyType == 'I'" class="f-c-b">
                    <el-button class="f1" type="success" @click="userAgreeJoinEnterprise(DetailItem.applyId)">加入</el-button>
                    <el-button class="f1" type="danger" @click="userRefuseJoinEnterprise(DetailItem.applyId)">拒绝</el-button>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
export default {
    inject: ['reload'],
    data() {
        return {
            dialogShow: false,
            EnterpriseInfo: {},
            EnterpriseDetails: {},
            detailInfo: [],
            eApply: '',
            eimage1: '',
            page: 1,
            size: 10,
            DetailItem: {}
        }
    },
    created() {
        this.userFindJoinEnterprise()
    },
    methods: {
        // 刷新当前页面
        refresh() {
            this.reload();
        },
        // 用户查看申请或邀请加入企业列表接口
        userFindJoinEnterprise() {
            let that = this;
            that.$http.userFindJoinEnterprise({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page: that.page,
                size: that.size
            }).then(res => {
                this.EnterpriseInfo = res.data
            })
        },
        // 用户查看申请或邀请加入企业列表详情接口
        showEnterpriseInfo(data) {
            let that = this, { enterpriseId, applyId } = data;
            that.DetailItem = data;
            that.$http.userFindJoinEnterpriseDetails({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                enterpriseId,
                applyId
            }).then(res => {
                this.dialogShow = true
                let { address, contactTelephone, enterpriseName, eimage1 } = res.data.enterprise,
                    { eApply } = res.data;
                this.eimage1 = eimage1
                this.detailInfo = [{
                    "title": "企业名称",
                    "value": enterpriseName
                }, {
                    "title": "联系电话",
                    "value": contactTelephone
                }, {
                    "title": "详细地址",
                    "value": address
                }];
                this.eApply = eApply
            }).catch(() => { })
        },
        // 用户撤销加入企业或用户撤销退出企业接口
        userRevokeJoinEnterprise(applyId) {
            let that = this;
            that.$confirm('撤销退出', '提示').then(() => {
                that.$http.userRevokeJoinEnterprise({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack();
                    }
                })
            }).catch(() => { })
        },
        // 用户清空指定消息
        userApprovalEmptyOne(applyId) {
            let that = this;
            that.$confirm('是否清除当前消息？', '提示').then(() => {
                that.$http.userApprovalEmptyOne({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.refresh()
                    }
                })
            }).catch(() => { })
        },
        // 用户同意加入企业接口
        userAgreeJoinEnterprise(applyId) {
            let that = this;
            that.$confirm('确定加入该企业', '提示').then(() => {
                that.$http.userAgreeJoinEnterprise({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack();
                    }
                })
            }).catch(() => { })
        },
        // 用户拒绝加入企业接口
        userRefuseJoinEnterprise(applyId) {
            let that = this;
            that.$confirm('拒接加入该企业', '提示').then(() => {
                that.$http.userRefuseJoinEnterprise({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        // that.$common.DelayedBack();
                        that.refresh()
                    }
                })
            }).catch(() => { })
        },
        // 用户申请退出企业接口
        userApplyQuitEnterprise(enterpriseId) {
            let that = this;
            that.$confirm('确定退出该企业？', '提示').then(() => {
                that.$http.userApplyQuitEnterprise({
                    enterpriseId,
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token')
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.refresh()
                    }
                })
            }).catch(() => { })
        },
        // 关闭弹出
        closeDialog() {
            this.dialogShow = false
        },

    }
}
</script>
<style lang="less" scoped>
.EnterpriseStateInfo {
    .info-item {
        border-bottom: 1px solid #7a7a7a;
        padding: 15px 10px;

        .base-info {
            margin-left: 30px;

            span:first-child {
                font-size: 20px;
            }

            span:nth-child(2) {
                margin: 10px 0;
                font-size: 14px;
            }

            span:last-child {
                font-size: 14px;
                color: red;
            }
        }

        .el-button {
            margin-right: 30px;
        }
    }

    .info-item:hover {
        background-color: aliceblue;
        cursor: pointer;
    }

    .enterprise-details {
        .el-image {
            margin: auto;
        }

        .details-item {
            margin: 12px 0;
            font-size: 16px;
        }

        .details-state {
            margin: 20px auto;
            font-size: 18px;
            color: red;
            width: 80%;
            text-align: center;
            line-height: 30px;
        }
    }
}
</style>